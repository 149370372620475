import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage as Image, getImage } from 'gatsby-plugin-image';
import * as React from "react";

import Breadcrumbs from '../components/Breadcrumbs';
import Button from '../components/Button';
import Seo from '../components/Seo';

import FacebookIcon from '../components/icons/FacebookIcon';
import TelegramIcon from '../components/icons/TelegramIcon';
import ThumbsUpIcon from '../components/icons/ThumbsUpIcon';
import TwitterIcon from '../components/icons/TwitterIcon';

const AboutPage = ({ pageContext }) => {
  const data = useStaticQuery(graphql`
    query {
      lidaPhoto: file(relativePath: {eq: "photos/lida.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: NONE
            width: 600
            height: 800
          )
        }
      }
      olehPhoto: file(relativePath: {eq: "photos/oleh.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: NONE
            width: 600
            height: 800
          )
        }
      }
      tales: allTale {
        totalCount
      }
      songs: allSong {
        totalCount
      }
    }
  `);

  const talesCount = Math.floor(data.tales.totalCount / 10) * 10;
  const songsCount = Math.floor(data.songs.totalCount / 10) * 10;

  return (
    <>
      <Seo
        title="Про проєкт | Перлини української народної творчості"
        descriptions="Дізнайтесь більше про наш проєкт, його мету та цілі."
      />
      <div className="bg-red-500 text-white">
        <div className="container-lg">
          <div className="row items-center">
            <div className="col-full md:col-6 py-10 md:py-24">
              <Breadcrumbs
                crumbs={pageContext.breadcrumb.crumbs}
                crumbLabel="Про проєкт"
              />
              <h1 className="typo-h1 max-w-sm lowercase mt-4">
                Про проєкт
              </h1>
            </div>
            <div className="col-full md:col-6">
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg my-12 md:mt-24">
        <h2 className="typo-h2 mb-4">
          Історія проєкту
        </h2>
        <p className="typo-body">
          Ідея виникнення сайту з’явилася ще у 2018 році, коли до наших рук потрапила збірка українських народних
          жартів та анекдотів "Дніпровські сміховини". Сайт з жартами збірки був створений на початку 2022 року. Згодом
          ми вирішили розширити список категорій народних творів зібраних на сайті. Було додано пісні різноманітних
          жанрів, казки, прислів'я, приказки та інші категорії. Сайт продовжує наповнюватись, і вже має
          більше
          {' '}
          {talesCount}
          {' '}
          казок,
          {' '}
          {songsCount}
          {' '}
          пісень та велику кількість творів інших жанрів.
        </p>
        <h2 className="typo-h2 mt-12 mb-4">
          Мета проєкту
        </h2>
        <p className="typo-body">
          Метою проєкту є зібрати та зберегти пісні, казки, та твори інших категорій української народної творчості в
          електронному вигляді. Ми хочему, щоб відвідувачі нашого сайту, перебуваючи в будь-якому куточку світу мали
          доступ до обширного списку казок, пісень, прислів’їв та приказок, сміховин, скоромовок та відео-,
          аудіо-матеріалів до них.
        </p>
        <div className="row mt-12">
          <div className="col-full md:col-5">
            <h2 className="typo-h2 mb-4">
              Команда проєкту
            </h2>
            <p className="typo-body">
              Ентузіасти, які люблять історію, традиції та культуру свого народу. Ми знаходимо культурні скарби наших
              предків та публікуємо їх на цьому сайті.
            </p>
          </div>
          <div className="col-6 sm:col-6 md:col-3 md:ml-1/12 mt-4 md:mt-0">
            <Image
              image={getImage(data.lidaPhoto)}
              alt="Lida Basmat"
            />
            <p className="typo-small mt-2">
              Лідія Басмат
            </p>
          </div>
          <div className="col-6 sm:col-6 md:col-3 mt-4 md:mt-0">
            <Image
              image={getImage(data.olehPhoto)}
              alt="Oleh Pratsko"
            />
            <p className="typo-small mt-2">
              Олег Працько
            </p>
          </div>
        </div>
        <h2 className="typo-h2 mt-12 mb-4">
          Долучайтесь до наших спільнот
        </h2>
        <div className="flex items-center">
          <Button to="https://www.facebook.com/folklore.com.ua" variant="cta" size="xs" className="mr-4">
            Facebook
            <FacebookIcon className="inline-block h-6 w-6 ml-4" />
          </Button>
          <Button to="https://t.me/folklorecomua" variant="cta" size="xs" className="mr-4">
            Telegram
            <TelegramIcon className="inline-block h-6 w-6 ml-4" />
          </Button>
          <Button to="https://twitter.com/folklorecomua" variant="cta" size="xs">
            Twitter
            <TwitterIcon className="inline-block h-6 w-6 ml-4" />
          </Button>
        </div>
        <h2 className="typo-h2 mt-12 mb-4">
          Дізнайтесь як допомогти проєкту
        </h2>
        <div className="flex items-center">
          <Button to="https://www.patreon.com/folklorecomua" variant="primary" size="xs">
            Підтримати проєкт
            <ThumbsUpIcon className="inline-block h-6 w-6 ml-4" />
          </Button>
        </div>
      </div>
    </>
  );
};

export default AboutPage;
